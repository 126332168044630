<template>
	<div class="notice_popup human int">
		<div class="close" @click="$emit('close')">
			<img src="/images/close.png" alt="닫기"/>
		</div>
		<div class="tit" style="margin-left: 9px;font-size: 24px;" v-if="param.expiredTime >  0">로그인 상태를 연장하시겠습니까?</div>
		<div class="tit" style="margin-left: 9px;font-size: 24px;" v-if="param.expiredTime <= 0">일정시간 사이트 사용이 없어<br/>자동로그아웃 되었습니다.</div>
		<div class="select-btn">
			<div class="btn" style="background-color: #75A5BF;" v-if="param.expiredTime >  0" @click="refresh()">확인</div>
			<div class="btn" style="background-color: #75A5BF;" v-if="param.expiredTime <= 0" @click="goLogin()">확인</div>
		</div>
	</div>
</template>

<script>
export default {
	props : ['param'],
	data() {
		return {
		};
	},
	methods: {
		refresh(){
			this.$.loading(true);
			this.$axios.post(this.$.API_REFRESH)
				.then(res => {
					//console.log('sessionTime', 'refreshToken success', res);
					this.$store.commit('userToken', res.data);
					this.$.loading(false);
				}).catch(() => {
					//console.log('sessionTime', 'refreshToken error', err);
					alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
					this.goLogin();
				});
		},
		goLogin(){
			this.$emit('close', false);
		},
	},
	computed: {
		expiredTime(){
			var expiredTime = Math.floor(this.param.expiredTime / 1000);
			var ret = '';
			if(expiredTime >= 60){
				ret += Math.floor(expiredTime / 60) + '분';
			} 
			if(expiredTime % 60 != 0){
				if(ret != '') ret += ' ';
				ret += (expiredTime % 60) + '초';
			}

			if(ret == '') ret += '0초';
			
			return ret;
		},
	}
};
</script>